<template>
    <div class="tw-flex tw-justify-center">
        <div class="sculptor">
            <div class="top-section tw-flex tw-items-center">
                <p>Departments</p>
                <v-btn class="add-unit-btn ml-3" @click="$router.push({name: 'AddDepartment'})">
                    <icons name="add-unit" class="mr-2" /> Add Departments
                </v-btn>
            </div>

            <div>
                <div class="total-unit">
                   <div class="tw-flex tw-items-center">
                        <icons name="depts" />
                        <span class="darker-text ml-1">Total Departments</span>
                        <v-spacer></v-spacer>
                        <span class="lighter-text">Today</span>
                    </div>
                    <p class="total-unit-figure mt-3">{{totaldepts}}</p>
                    <div class="tw-flex tw-justify-end">
                        <icons name="temp-graph" />
                    </div>
                </div>
                <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
                    <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
                        <h6 class="header">All Departments</h6>
                        <div class="mr-2 tw-mt-5 lg:tw-mt-0 "
                            style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)">
                            <v-text-field placeholder="Search" class="p-0" solo flat hide-details v-model="search">
                                <template #prepend>
                                    <v-icon :color="color" class="ml-5">
                                        mdi-magnify
                                    </v-icon>
                                </template>

                                <template #append>
                                    <v-icon :color="color">
                                        mdi-filter-variant
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </div>
                        <v-data-table :headers="headers" :mobile-breakpoint="0" :search="search" item-key="id"
                            :single-select="false" height="available" :hide-default-footer="depts.length <= 7"
                            :items="depts" :no-data-text="not_found" class="mt-4 mx-1 table" :loading="loading"
                                      @click:row="routeToPage">

                            <template #header.profile>
                                <span class="status">Profile</span>
                            </template>

                            <template v-slot:[`item.profile`]="{ item }">
                                <div class="circle">
                                    <span class="td-style">{{ getFirstLetter(item.name) }}</span>
                                </div>
                            </template>
                            <template #header.deptName>
                                <span class="status">Department</span>
                            </template>

                            <template v-slot:[`item.deptName`]="{ item }">
                                <span class="td-style">{{ item.name }}</span>
                            </template>
                            <template #header.unitHead>
                                <span class="status">H.O.D</span>
                            </template>

                            <template v-slot:[`item.unitHead`]="{ item }">
                                <span class="td-style">{{ item.createdBy }}</span>
                            </template>
                            <template #header.unit>
                                <span class="status">Unit</span>
                            </template>

                            <template v-slot:[`item.unit`]="{ item }">
                                <span class="td-style">{{ item.unitName }}</span>
                            </template>

                            <template #header.paddlers>
                                <span class="status">Paddlers</span>
                            </template>

                            <template v-slot:[`item.paddlers`]="{ item }">
                                <span class="td-style">{{item.totalPaddlers }}</span>
                            </template>
                            <template #header.dateCreated>
                                <span class="status">Date Created</span>
                            </template>

                            <template v-slot:[`item.dateCreated`]="{ item }">
                                <span class="td-style">{{ formatDate(item.createdAt) }}</span>
                            </template>
                            <template #header.actions>
                                <span class="status">Actions</span>
                            </template>

                            <template v-slot:[`item.actions`]="{item}">
                                <v-menu bottom offset-y left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <td class="d-flex justify-end">
                                            <v-btn v-bind="attrs" v-on="on" icon>
                                                <v-icon :color="color">mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <v-list>

                                            <v-list-item-title style="color: black"
                                                class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                                                <icons name="view-paddler" class="mr-2" />
                                                View department
                                            </v-list-item-title>

                                        <v-list-item class="" @click="routeToUpdateDept(item)">
                                            <v-list-item-title style="color: black"
                                                class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                                                <icons name="update-paddler" class="mr-2" />
                                                Update department
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item class="" @click="transferDepartment(item)">
                                            <v-list-item-title style="color: black"
                                                class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                                                <icons name="tools" class="mr-2" />
                                                Transfer department
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>

                                </v-menu>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </div>
        <transfer-department :toggleModal="showModal" @close-modal="closeModal" />

    </div>
</template>

<script>
    import Icons from '../../../components/reuseables/Icons.vue';
    import {
        getAllDept,
        getAllUnits
    } from '../../../services/api/APIService';
    import TransferDepartment from './TransferDepartment.vue';
    export default {
        name: "AllDepartments",
        components: {
            Icons,
            TransferDepartment
        },
        data() {
            return {
                color: "#004AAD",
                search: "",
                showModal: false,
                depts: [],
                not_found: "Departments will show here",
                headers: [{
                        text: "",
                        value: "profile"
                    },
                    {
                        text: "",
                        value: "deptName"
                    },
                    {
                        text: "",
                        value: "unitHead"
                    },
                    {
                        text: "",
                        value: "unit"
                    }, {
                        text: "",
                        value: "paddlers"
                    },
                    {
                        text: "",
                        value: "dateCreated"
                    },
                    {
                        text: "",
                        value: "actions",
                        align: "end"
                    },
                ],
                loading: false,
                totaldepts: 0,
                totalPaddlers: 0,
                units:[]
            }
        },

        methods: {
            getFirstLetter(unit) {
                return unit.charAt(0)
            },
            getTotalPaddlers(value) {
                return value ? value.length : 0;
            },
            formatDate(apiDate) {
                const options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                };
                const date = new Date(apiDate);
                return date.toLocaleDateString('en-US', options);
            },
            routeToUpdateDept(dept) {
                this.$store.dispatch('paddler/setSelectedDept', dept)
                this.$router.push({
                    name: "UpdateDepartment"
                })
            },
          routeToViewDepartment(){
            this.$router.push({name:"ViewDepartments"})
          },
            transferDepartment(dept) {
                this.$store.dispatch('paddler/setSelectedDept', dept)
                this.showModal = true
            },
            async getAllDept() {
                this.loading = true
                await getAllDept().then(res => {
                    this.depts = res.data
                    this.totaldepts = this.depts.length
                    this.loading = false
                }).catch(err => {
                    this.$displaySnackbar({
                        message: err.response.data.details[0],
                        success: false
                    })
                    this.loading = false
                })
            },
            async getAllUnits() {
                await getAllUnits().then(res => {
                    this.units = res.data;
                });
            },
            closeModal(){
                this.showModal = false
                this.getAllDept()
            },
            routeToPage(item){
                this.$store.dispatch('paddler/setSelectedDept', item)
                this.$router.push({name: 'ViewDepartments'})
            }
        },
        async created() {
            await this.getAllDept()
            await this.getAllUnits()
        },
    }
</script>

<style lang="scss" scoped>
    .sculptor {
        width: 1000px;

        @media (max-width: 1024px) {
            width: 100%;
        }
    }

    .top-section p {
        color: #263238;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
    }

    .add-unit-btn {
        display: flex;
        color: #004AAD;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
        width: fit-content;
        height: fit-content;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        letter-spacing: 0px;
        padding: 12px;
        border-radius: 10px;
    }

    .add-unit-btn:hover {
        background-color: #c1bfbf4c;
    }

    .total-unit {
        width: 330px;
        height: 150px;
        border-radius: 10px;
        padding: 22px;
        margin-top: 20px;
        background: #FEFFFF;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);
    }

    .categories-box {
        width: 200px;
        height: fit-content;
        padding: 10px 15px;
        flex-shrink: 0;
        margin-top: 20px;
        border-radius: 10px;
        background: #FEFFFF;
        margin-left: 20px;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);

        @media (max-width: 650px) {
            margin-left: 0;
        }
    }

    .categories-box,
    .total-unit {
        @media (max-width: 650px) {
            width: 100%;
        }
    }

    .darker-text,
    .lighter-text {
        color: #263238;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .lighter-text {
        color: #838383;
    }

    .total-unit-figure {
        color: #4F4F4F;
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .small-darker-text {
        font-size: 16px;
    }

    .status-tags {
        font-family: Inter;
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .company-dashboard {
        display: flex;
        background: #FFFFFF;
        width: 100%;
        min-height: 70vh;
        max-height: available;
        border-radius: 10px;
        flex-direction: column;
    }

    .card-sculptors {
        @media (max-width: 650px) {
            flex-direction: column;
        }
    }

    .header {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #004AAD;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .circle {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: #7CB0F6;
        /* Set your desired background color here */
        border-radius: 50%;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: white;

    }

    .table {
        width: 100%;
    }

    .v-data-table__wrapper>table {
        margin-top: 55px !important;
    }

    .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th:first-child,
    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
        padding-left: 16px;
    }

    .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
    .status {
        color: #939393;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
    .td-style {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 15px;
        letter-spacing: 0;
        text-align: left;
        text-transform: capitalize;
    }

    .company-logo {
        height: 100px;
        width: 100px;
        border-radius: 10px;
        object-fit: contain;
    }

    .no-image {
        display: flex;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-style: normal;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        text-align: center;
    }
</style>