<template>
  <div>
    <v-dialog v-model="toggleModal" persistent transition="dialog-bottom-transition">
      <v-card>
        <p class="add-pd mb-6">Transfer Department</p>
        <div>
          <div>
            <label class="lighter-text">Departments</label>
            <v-text-field class="mt-4" hide-details disabled v-model="selectedDept.name" placeholder="Select department"
              solo>
            </v-text-field>
          </div>
          <div class="tw-flex tw-justify-center mt-4">
            <icons name="place-item" />
          </div>
          <div>
            <validation-provider name="unit name" rules="required" v-slot="{ classes, errors }">
              <label class="lighter-text">Unit</label>
              <v-select class="mt-4" :items="units" hide-details item-text="name"
                placeholder="Select the target unit for the transfer" solo v-model="selectedDept.unitName">
                <template #append>
                  <icons name="select-dropdown" />
                </template>
              </v-select>
              <span class="error-text">{{errors[0]}}</span>
            </validation-provider>
          </div>
        </div>
        <v-card-actions class="tw-flex tw-justify-end mt-5">
          <v-btn @click="closeModal" class="ctrl-btn back">Cancel</v-btn>
          <v-btn @click="validateEntry" class="ctrl-btn next" :disabled="selectedUnitId === ''">Transfer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" max-width="500">
      <v-card>
        <div class="add-pd">Confirm Transfer</div>
        <p class="lighter-text">
          Are you sure you want to transfer the ' <span class="lighter-text"
            style="font-weight:bold">{{this.selectedDept.name}}</span>' department to ' <span class="lighter-text"
            style="font-weight:bold">{{ this.selectedUnitName}}</span>'
          unit?
        </p>
        <v-card-actions class="tw-flex tw-justify-end mt-5">
          <v-btn @click="toggleConfirmDialog" class="back">No</v-btn>
          <v-btn @click="transferDepartment" class="next" :loading="loading">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mapGetters
  } from 'vuex';
  import Icons from '../../../components/reuseables/Icons.vue';
  import {
    getAllUnits,
    transferDepartment
  } from '../../../services/api/APIService';
  export default {
    components: {
      Icons
    },
    name: "TransferDepartment",
    data() {
      return {
        units: [],
        confirmDialog: false,
        selectedUnit: '',
        selectedUnitName:'',
        loading: false,
        selectedUnitId: ""
      };
    },
    props: {
      toggleModal: {
        default: true,
        type: Boolean
      }
    },
    methods: {
      async getAllUnits() {
        try {
          const response = await getAllUnits();
          this.units = response.data;
        } catch (error) {
          console.error('Error fetching units:', error);
        }
      },
      toggleConfirmDialog() {
        this.confirmDialog = !this.confirmDialog
      },
      closeModal() {
        this.$emit('close-modal')
      },
      validateEntry() {
        console.log(this.selectedDept.unitId, this.selectedUnitId)

        if (this.selectedDept.unitId === this.selectedUnitId) {
          this.$displaySnackbar({
            message: "Department already exist in target unit",
            success: false
          })
        }
        else{
          this.confirmDialog =true
        }
      },
      async transferDepartment() {
        this.loading = true
        let data = {}
        data.id = this.selectedDept.id
        data.unitId = this.selectedUnitId
        console.log(data)
        await transferDepartment(data).then(() => {
          this.loading = false
          this.$displaySnackbar({
            message: "Department transferred successfully",
            success: true
          })
        }).catch(err => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false
          })
        })
        this.confirmDialog = false
        this.closeModal()
      }
    },
    computed: {
      ...mapGetters("paddler", ["selectedDept"])
    },
    created() {
      this.getAllUnits()
    },

    watch: {
      'selectedDept.unitName': {
        immediate: true,
        handler(newValue) {
          if (newValue && this.units.length > 0) {
            const selectedUnit = this.units.find(unit => unit.name === newValue);
            if (selectedUnit) {
              this.selectedUnitName = selectedUnit.name;
              this.selectedUnitId = selectedUnit.id
            }
          }
        },
      },
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-dialog {
    box-shadow: none !important;
    width: 80% !important;

    @media screen and (min-width: 768px) {
      width: 700px !important;
    }
  }

  .v-card {
    padding: 40px;
  }

  .add-pd {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 15px;

    @media (max-width:768px) {
      justify-content: start;
      margin-top: 13px;
      font-size: 17px;
    }
  }

  .ctrl-btn {
    width: 100px;
    height: 30px;
    border-radius: 10px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize !important;
    letter-spacing: 0;
  }

  .darker-text,
  .lighter-text {
    color: #263238;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  .lighter-text {
    color: #838383;
  }

  ::v-deep .v-btn.next {
    color: #ffff;
    background-color: #004aad !important;
  }

  ::v-deep .v-btn.back {
    color: #004aad;
    background-color: #ffff;
    border: 2px solid #004aad;
  }
</style>